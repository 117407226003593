$(document).ready(function () {
  // global variables

  const orderPrice = document.querySelector(".order_price");
  let orderInfo;

  // utiliy functions

  function resetForm() {
    let form = document.querySelector("#signUpForm");
    form.reset();
  }

  $("#carouselExample").carousel({
    interval: 2000,
  });

  $("#personalizarLogo").change(function (e) {
    e.preventDefault();
    let orderPriceValue = orderPrice.innerText.split(" ")[1];

    if (!this.checked) {
      orderPrice.innerText = "$ " + (+orderPriceValue - 0);
    } else if (this.checked) {
      $("#logoModal").modal({ show: true });

      orderPrice.innerText = "$ " + (+orderPriceValue + 0);
    } else {
      return;
    }
  });

  /////////////////////global variable selected chocotejas///////////////////////////////////

  let chocotejas_selected = {};

  // input security chocoteja quantity selections TODO

  function orderSubmit(allInputs, orderPriceValue, orderQuantity) {
    Date.prototype.toDateInputValue = function () {
      var local = new Date(this);
      local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
      return local.toJSON().slice(0, 10);
    };

    $('input[type="date"]')[0].min = new Date().toDateInputValue();

    let logoPersonalizado = $("#personalizarLogo").is(":checked") ? "si" : "no";

    //console.log("all chocotjeas selected ", orderPriceValue, orderQuantity);
    orderInfo = {
      papel: allInputs[0].value,
      colorPapel: allInputs[1].value,
      lazo: allInputs[2].value,
      colorLazo: allInputs[3].value,
      logoPersonalizado,
      cantidadTotal: orderQuantity,
      precioTotal: orderPriceValue,

      chocotejasEscogidas: chocotejas_selected,
    };

    // persist order

    localStorage.setItem("orderInfo", JSON.stringify(orderInfo));

    //console.log("order ", orderInfo);
  }

  $("#clientInfo").on("submit", function (e) {
    e.preventDefault();
    let clientInfoForm = document.querySelector("#clientInfo");
    const formSuccessContainer = document.querySelector(
      ".clientInfo_form_success_message"
    );

    //e.stopPropagation();
    clientInfoForm.querySelector("#orderInfo").value =
      JSON.stringify(orderInfo);

    const clientInfo = new FormData(clientInfoForm);

    //clientInfo.append("name", JSON.stringify(orderInfo))

    fetch(clientInfoForm.getAttribute("action"), {
      method: "POST",
      headers: {
        Accept: "application/x-www-form-urlencoded;charset=UTF-8",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: new URLSearchParams(clientInfo).toString(),
    })
      .then((response) => {
        window.dataLayer.push({
          event: "event_form_submit",
          eventFormStatus: "success",
        });

        formSuccessContainer.innerHTML =
          '<div class="alert alert-success" role="alert">¡Tu cotizacion ha sido exitosa, te responderemos pronto!</div>';
        clientInfoForm.reset();

        setTimeout(function () {
          $("alert").alert("close");
          $("#clientInfoModal").modal("hide");
        }, 3000);

        // client info form success
      })
      .catch((error) => {
        window.dataLayer.push({
          event: "event_form_submit",
          eventFormStatus: "error",
          error: error,
        });

        alert(error);
      });
  });

  $("#continuarCotizacion").click(function (e) {
    e.preventDefault();
    e.stopPropagation();

    let allSelectedInputs = $(".events_filters input:checked");

    const validationResult = Array.from(allSelectedInputs).some((input) => {
      if (input.checked && input.disabled === true) {
        return true;
      }
    });

    //orderPrice.innerText.replace(/\$\s/g, "");
    let orderPriceValue = orderPrice.innerText.split(" ")[1];
    let orderQuantity = document
      .querySelector(".order_quantity")
      .innerText.replace(/\s/g, "");
    let logoPersonalizado = $("#personalizarLogo").is(":checked") ? "si" : "no";

    const logoCondition =
      logoPersonalizado === "si" && orderQuantity >= 200
        ? true
        : logoPersonalizado === "no"
        ? true
        : false;

    if (!validationResult && +orderQuantity >= 30 && logoCondition) {
      //console.log("logo condition ", logoCondition);
      $("#clientInfoModal").modal({ show: true });

      orderSubmit(allSelectedInputs, orderPriceValue, orderQuantity);
    } else {
      alert(
        "Asegurate de completar todos los pasos y que tengas las cantidades minimas correctas"
      );
    }
  });

  async function getFilteredImages() {
    const response = await fetch("filterImages.json");
    const json = response.json();

    return json;
  }

  const imageInputSelection = async function (inputArray) {
    let filterImages = await getFilteredImages();

    let eventPhotos = filterImages.events_photos;

    let papel = inputArray[0] ? inputArray[0].id : null;
    let papelColor = inputArray[1] ? inputArray[1].id : null;
    let lazoCordon = inputArray[2] ? inputArray[2].id : null;
    let lazoCordonColor = inputArray[3] ? inputArray[3].id : null;

    let inputConcat = [papel, papelColor, lazoCordon, lazoCordonColor]
      .filter((input) => input !== null)
      .join("_");

    // create a unique id that involves the combination of 4 level of selected filters

    //seda_crema_cola_raton_blanco
    let variationContainer = {
      seda_crema_cola_raton_l4blanco: "variation_1",
      seda_crema_cola_raton_l4rosado: "variation_2",
      seda_crema_cola_raton_l4celeste: "variation_3",

      seda_crema_hilo_l4dorado: "variation_4",
      seda_crema_hilo_l4plateado: "variation_5",
      seda_dorado_hilo_l4dorado: "variation_6",
      seda_plateado_hilo_l4plateado: "variation_7",

      seda_azul_cola_raton_l4blanco: "variation_8",
      seda_turquesa_cola_raton_l4blanco: "variation_9",
      seda_amarillo_cola_raton_l4blanco: "variation_10",
      seda_rosado_cola_raton_l4blanco: "variation_11",
      seda_morado_cola_raton_l4blanco: "variation_12",

      cera_blanco_cola_raton_l4blanco: "variation_13",
      cera_blanco_cola_raton_l4rosado: "variation_14",
      cera_blanco_cola_raton_l4celeste: "variation_15",

      cera_blanco_hilo_l4dorado: "variation_16",
      cera_blanco_hilo_l4plateado: "variation_17",

      cera_blanco_cabuya_l4beige: "variation_18",

      cera_blanco_cola_raton_l4gama: "variation_19", // default ?

      //addition
      seda_morado_cola_raton_blanco: "variation_20",

      // partial variations

      seda: "partial_variation_20",
      seda_crema_cola_raton: "partial_variation_21",
      seda_crema_hilo: "partial_variation_22",

      seda_dorado: "partial_variation_23",
      seda_dorado_hilo: "partial_variation_24",

      seda_plateado: "partial_variation_25",

      // all the rest

      seda_azul: "partial_variation_26",
      seda_turquesa: "partial_variation_26",
      seda_amarillo: "partial_variation_26",
      seda_rosado: "partial_variation_26",
      seda_morado: "partial_variation_26",

      cera: "partial_variation_27",
      cera_blanco: "partial_variation_31",
      cera_blanco_cola_raton: "partial_variation_28",
      cera_blanco_hilo: "partial_variation_29",

      cera_blanco_cabuya: "partial_variation_30",
    };

    if (variationContainer[inputConcat]) {
      const selectedVariation = variationContainer[inputConcat];

      const filterImage = eventPhotos[selectedVariation];

      // sessionStorage.set('input' , selectedVariation)

      return filterImage;
    } else {
      // disable last selection for incorrect matches

      while (!variationContainer[inputConcat]) {
        inputConcat = inputConcat.split("_").slice(0, -1).join("_");
      }

      const filterInfo = eventPhotos[variationContainer[inputConcat]];
      //console.log('chopped input', variationContainer[inputConcat])

      return filterInfo;
    }
  };

  $(".flavour_quantity input").on("input", function () {
    var $input = $(this);
    var oldValue = $input.parent().find("input").val();

    let chocotejaQuantityCalculator = () => {
      let inputsSelected = Array.from(
        document.querySelectorAll(".flavour_quantity input")
      );
      let totalPrice = inputsSelected.reduce((accumulator, input) => {
        // update chocotejas selected

        if (input.value > 0) {
          chocotejas_selected[input.id] = input.value;
        }

        if (input.checked || input.classList[0] === "chocoteja_quantity") {
          let inputNumber = parseInt(input.value);

          return accumulator + inputNumber;
        }

        return accumulator;
      }, 0);

      return totalPrice;
    };

    //let orderPrice = document.querySelector('.order_price');
    let orderQuantity = document.querySelector(".order_quantity");
    let orderMessage = document.querySelector(".order_message");
    let chocotejasQuantity = chocotejaQuantityCalculator();

    const promotionsPriceAssessment = function (chocotejasQuantity) {
      if (chocotejasQuantity >= 300) {
        return {
          price: chocotejasQuantity - chocotejasQuantity * 0.2,
          discount: "20%",
        };
      } else if (chocotejasQuantity >= 100) {
        return {
          price: chocotejasQuantity - chocotejasQuantity * 0.1,
          discount: "10%",
        };
      } else if (chocotejasQuantity >= 30) {
        return { price: chocotejasQuantity, discount: false };
      }

      return { price: chocotejasQuantity, discount: false };
    };
    let logoPersonalizado = $("#personalizarLogo").is(":checked") ? 0 : 0;
    const chocotejasfinalPrice = promotionsPriceAssessment(chocotejasQuantity);
    let chocotejasMessage =
      chocotejasfinalPrice && chocotejasfinalPrice.discount
        ? `<div class="alert alert-success" role="alert"> ¡${chocotejasfinalPrice.discount} De Descuento Aplicado! </div>`
        : `<div class="alert alert-primary" role="alert">¡Recibe los siguientes descuentos si tu compra es de:<br/>  100 o más unidades: -10%,<br/> 300 o más unidades: -20%!
</div>`;

    //console.log("chocotejas price ", chocotejasfinalPrice);
    //console.log("logo", logoPersonalizado);

    if (orderQuantity.innerText !== "NaN") {
      orderPrice.innerText =
        "$ " + (+chocotejasfinalPrice.price + +logoPersonalizado);
      orderQuantity.innerText = chocotejasQuantity;
      orderMessage.innerHTML = chocotejasMessage;
    }

    //ending
  });

  $(".filter_selections input").on("click", async function () {
    //1- get all inputs selected

    allSelectedInputs = $(".events_filters input:checked");

    //2- store those inputs in an array to be passed to the dataStructure function

    let filterInfo = await imageInputSelection(allSelectedInputs);

    // console.log("original filter src ", filterInfo);

    let carousel = document.querySelectorAll(".carousel-item img");

    if (filterInfo && filterInfo.link) {
      //carousel[0].src = filterInfo.link;
      $(carousel[0]).attr("src", filterInfo.link);

      if (filterInfo.carousel_links && filterInfo.carousel_links.length) {
        $(carousel[1]).attr("src", filterInfo.carousel_links[0]);
        $(carousel[2]).attr("src", filterInfo.carousel_links[1]);
      }

      $(".carousel").carousel(0);
    } else {
      console.log(carousel, "carousel");

      $(carousel[0]).attr("src", "assets/images/eventos_comodin_default.jpg");
      $(carousel[1]).attr("src", "assets/images/eventos_comodin_default.jpg");
      $(carousel[2]).attr("src", "assets/images/eventos_comodin_default.jpg");
    }

    // disable according to variation

    let disableOptions = filterInfo.disableOptions;

    disabler(disableOptions);

    function disabler(disableOptions) {
      function disable(disableOptions, inputLevel) {
        let result = inputLevel.filter((input) => {
          let inputId = input.id;
          let level = input.getAttribute("data-inputLevel");
          let availableInput = document.querySelector(
            `[data-inputlevel="${level}"]:not(:disabled)`
          );

          if (disableOptions[level] && disableOptions[level][inputId]) {
            input.disabled = true;

            //console.log("input was disabled ", input);

            if (input.checked === true && availableInput) {
              availableInput.checked = true;
              availableInput.click();
            }
          } else if (input.disabled) {
            // console.log("inputs enabled ",input,"available input ",availableInput,level);

            input.disabled = false;

            const levelsPassed = {};

            if (input && !levelsPassed.hasOwnProperty(level)) {
              //console.log("inputs enabled internal", availableInput);
              levelsPassed[level] = true;

              input.checked = true;
              input.click();
            }
          }
        });
      }

      if (disableOptions) {
        let allInputs = Array.from($("[data-inputLevel]"));
        disable(disableOptions, allInputs);
      }
    }
  });

  $(document).ready(function () {
    /* show lightbox when clicking a thumbnail */
    $("a.thumb").click(function (event) {
      event.preventDefault();
      var content = $(".modal-body");
      content.empty();
      var title = $(this).attr("title");
      $(".modal-title").html(title);
      content.html($(this).html());
      $(".modal-profile").modal({ show: true });
    });
  });

  const navbarToggler = document.querySelector(".navbar-toggler");
  const navbarMenu = document.querySelector(".navbar-container ul");
  const navbarLinks = document.querySelectorAll(".navbar-container a");

  navbarToggler.addEventListener("click", navbarTogglerClick);

  function navbarTogglerClick() {
    navbarToggler.classList.toggle("open-navbar-toggler");
    navbarMenu.classList.toggle("open");
  }

  navbarLinks.forEach((elem) =>
    elem.addEventListener("click", navbarLinkClick)
  );

  function navbarLinkClick() {
    navbarLinks.forEach((link) => {
      if (link.classList.contains("active")) {
        link.classList.remove("active");
      }
    });
    this.classList.toggle("active");
    if (navbarMenu.classList.contains("open")) {
      navbarToggler.click();
      // navbarSocialLinks.append(navbarLinks);
    }
  }

  // contentful API

  const client = contentful.createClient({
    // This is the space ID. A space is like a project folder in Contentful terms
    space: "jh6tvf15sg3x",
    // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
    accessToken: "DMDKhvdewpkT98VoFDXg9EFJNx0Nj9BawfQPazQ8D50",
  });

  // client
  //   .getEntries()
  //   .then((response) => console.log("responsez", response.items));

  // variables

  const cartBtn = document.querySelector(".cart-btn");
  const closeCartBtn = document.querySelector(".close-cart");
  const clearCartBtn = document.querySelector(".clear-cart");
  const cartOverlay = document.querySelector(".cart-overlay");
  const cartItems = document.querySelector(".cart-items");
  const cartTotal = document.querySelector(".cart-total");
  const cartContent = document.querySelector(".cart-content");

  const cartDom = document.querySelector(".cart");
  const cardContainerDom = document.querySelector(".card_container");

  const pagePath = window.location.pathname;
  const itemType = pagePath.indexOf("blogs") > -1 ? "blogs" : "products";

  // cart
  let cart = [];

  // blogsArray
  let blogs = [];
  let buttonsDom;

  window.store = {
    localStoreSupport: function () {
      try {
        return "sessionStorage" in window && window["sessionStorage"] !== null;
      } catch (e) {
        return false;
      }
    },
    set: function (name, value, days) {
      if (this.localStoreSupport()) {
        sessionStorage.setItem(name, value);
      } else {
        document.cookie = name + "=" + value + expires + "; path=/";
      }
    },
    get: function (name) {
      if (this.localStoreSupport()) {
        var ret = sessionStorage.getItem(name);
        //console.log(typeof ret);
        switch (ret) {
          case "true":
            return true;
          case "false":
            return false;
          default:
            return ret;
        }
      }
    },
    del: function (name) {
      if (this.localStoreSupport()) {
        sessionStorage.removeItem(name);
      } else {
        this.set(name, "", -1);
      }
    },
  };

  class Items {
    async getItems() {
      try {
        let result =
          itemType === "blogs"
            ? await client.getEntries({ content_type: "tiaElenaBlogs" })
            : await fetch("products.json");

        let dataItems =
          itemType === "blogs" ? await result.items : await result.json();
        let itemsParsing =
          itemType === "blogs"
            ? dataItems.map((item) => {
                const { title, description, content } = item.fields;
                const image = item.fields.image[0].fields.file.url;
                const { id, createdAt } = item.sys;

                // const formattedDate = Utility.formatDate(createdAt);

                return { id, title, description, image, content, createdAt };
              })
            : dataItems.items;

        return itemsParsing;
      } catch (error) {
        console.log(error);
      }
    }
  }

  class UI {
    displayItems(items) {
      let result = "";

      items.forEach((item) => {
        result += `
      <div class="card" data-id=${item.id}>
      <div class="card_image">
          <img  src=${item.image}
              alt=${item.name} />
      </div>
      <div class="card_description">
          <h5 class="card_title">${item.name ? item.name : item.title}</h5>
          <p class="card_text">
             ${item.description}
          </p>
          ${
            itemType == "blogs"
              ? `<p style="font-size:1.4rem; margin:"2rem";>${Utility.formatDate(
                  item.createdAt
                )}</p>`
              : ""
          }

      </div>
      <div class="card_cta">

      <a class="button_component"  href=${
        itemType !== "blogs"
          ? "https://wa.me/c/593962863115"
          : "javascript:void(0)"
      } data-id=${item.id}> ${
          itemType !== "blogs" ? "¡Ver catálogo!" : "Ver Artículo"
        }</a>
    
      </div>
  </div>
      
      `;
      });

      // <button data-id=${item.id}>¡Añadir!</button>
      cardContainerDom.innerHTML = result;
    }

    async getBlogUrlParam(id) {
      let blogContent = Storage.getItem(id)
        ? Storage.getItem(id).content
        : (await client.getEntry(id)).fields.content;

      let options = {
        renderNode: {
          "embedded-asset-block": (node) =>
            ` <img  src="${node.data.target.fields.file.url}"/>`,
        },
      };

      if (blogContent) {
        let blogToRender = `
  <div class="base_container_blog">

    ${documentToHtmlString(blogContent, options)}

  </div>
  `;
        cardContainerDom.innerHTML = blogToRender;
      }
    }

    getItemsButtons() {
      const buttons = [
        ...document.querySelectorAll(".card_cta > .button_component"),
      ];
      buttonsDom = buttons;

      buttons.forEach((button) => {
        let id = button.dataset.id;
        let itemExists =
          itemType === "blogs"
            ? blogs.find((item) => item.id === id)
            : cart.find((item) => item.id === id);

        if (itemExists && itemType !== "blogs") {
          button.innerText = "Añadido En El Carrito";
          button.disabled = true;
        }
        button.addEventListener("click", (event) => {
          // event.target.innerText =  itemType !== "blogs" ? "¡Añadido En El Carrito!" : "Ver Blog";
          event.target.disabled = true;

          // get products
          let item =
            itemType !== "blogs"
              ? { ...Storage.getItem(id), amount: 1 }
              : { ...Storage.getItem(id) };

          // add product to the cart
          if (itemType === "blogs") {
            blogs = item;

            Storage.saveItem(blogs, "selectedBlog");

            let options = {
              renderNode: {
                "embedded-asset-block": (node) =>
                  ` <img  src="${node.data.target.fields.file.url}"/>`,
              },
            };

            let blog = `
            <div class="base_container_blog">

              ${documentToHtmlString(blogs.content, options)}
      
            </div>
            `;

            if (window.history && window.history.pushState) {
              window.addEventListener("popstate", function () {
                //alert('Back button was pressed.');
                window.location.reload();
              });
              window.history.pushState(
                null,
                null,
                window.location.href + "?blog=" + item.id
              );
            }

            cardContainerDom.innerHTML = blog;
          }
          //   else {

          //     cart = [...cart, item];

          //   // save cart in local storage

          //   Storage.saveItem(cart,"cart");

          //   // set cart logo values

          //   this.setCartValues(cart);

          //   // display cart items

          //   this.addCartItem(item)

          //   // show the cart

          //   if (screen.width > 600) {
          //     this.showCart();
          //   }
          // }
        });
      });
    }

    // setCartValues(cart) {
    //   let tempTotal = 0;
    //   let itemsTotal = 0;

    //   cart.map((item) => {
    //     tempTotal += item.price * item.amount;
    //     itemsTotal += item.amount;
    //   });

    //   cartTotal.innerText = parseFloat(tempTotal.toFixed(2));
    //   cartItems.innerText = itemsTotal;
    // }

    // addCartItem(item) {
    //   const div = document.createElement("div");
    //   div.classList.add("cart-item");
    //   div.innerHTML = `

    // <img src=${item.cartImageUrl} alt="" class="">
    // <div>
    //     <h4>${item.name}</h4>
    //     <h5>$${item.price}</h5>
    //     <span class="remove-item" data-id=${item.id}>
    //         Eliminar
    //     </span>
    // </div>
    // <div>
    //     <i class="fas fa-chevron-up" data-id=${item.id}></i>
    //     <p class="item-amount">${item.amount}</p>
    //     <i class="fas fa-chevron-down" data-id=${item.id}></i>
    // </div>
    // `;
    //   cartContent.appendChild(div);
    // }

    // showCart() {
    //   cartOverlay.classList.add("transparentBcg");
    //   cartDom.classList.add("showCart");
    // }

    // setupAPP() {
    //   cart = Storage.getCart();

    //   this.setCartValues(cart);
    //   this.populateCart(cart);
    //   cartBtn.addEventListener("click", this.showCart);
    //   closeCartBtn.addEventListener("click", this.hideCart);
    //   cartOverlay.addEventListener("click", function (e) {
    //     if (e.target === this) {
    //       cartOverlay.classList.remove("transparentBcg");
    //       cartDom.classList.remove("showCart");
    //     }
    //   });
    // }

    // populateCart(cart) {
    //   cart.forEach((item) => this.addCartItem(item));
    // }

    // hideCart() {
    //   cartOverlay.classList.remove("transparentBcg");
    //   cartDom.classList.remove("showCart");
    // }

    // cartLogic() {
    //   // clear cart button
    //   clearCartBtn.addEventListener("click", () => {
    //     this.clearCart();
    //   });
    //   // cart functionality
    //   cartContent.addEventListener("click", (event) => {
    //     if (event.target.classList.contains("remove-item")) {
    //       let removeItem = event.target;
    //       let id = removeItem.dataset.id;
    //       cartContent.removeChild(removeItem.closest(".cart-item"));
    //       this.removeItem(id);
    //     } else if (event.target.classList.contains("fa-chevron-up")) {
    //       let addAmount = event.target;
    //       let id = addAmount.dataset.id;

    //       let tempItem = cart.find((item) => item.id === id);
    //       tempItem.amount = tempItem.amount + 1;
    //       Storage.saveItem(cart, "cart");
    //       this.setCartValues(cart);
    //       addAmount.nextElementSibling.innerText = tempItem.amount;
    //     } else if (event.target.classList.contains("fa-chevron-down")) {
    //       let amount = event.target;
    //       let id = amount.dataset.id;
    //       let tempItem = cart.find((item) => item.id === id);

    //       if (tempItem.amount === 1) {
    //         this.removeItem(tempItem.id);

    //         cartContent.removeChild(amount.closest(".cart-item"));
    //       } else {
    //         tempItem.amount = tempItem.amount - 1;
    //         Storage.saveItem(cart, "cart");
    //         this.setCartValues(cart);
    //         amount.previousElementSibling.innerText = tempItem.amount;
    //       }
    //     }
    //   });
    // }

    // clearCart() {
    //   let cartItems = cart.map((item) => item.id);
    //   cartItems.forEach((id) => this.removeItem(id));
    //   while (cartContent.children.length > 0) {
    //     cartContent.removeChild(cartContent.children[0]);
    //   }
    //   this.hideCart();
    // }
    // removeItem(id) {
    //   cart = cart.filter((item) => item.id !== id);
    //   this.setCartValues(cart);
    //   Storage.saveItem(cart, "cart");
    //   let button = this.getSingleButton(id);
    //   button.disabled = false;
    //   button.innerHTML = `¡Añadir!`;
    // }

    // getSingleButton(id) {
    //   return buttonsDom.find((button) => button.dataset.id === id);
    // }
  }

  // local storage

  class Storage {
    // static is the keyword we use when we declare a method within a class object constructor

    static saveItems(items) {
      window.store.set(itemType, JSON.stringify(items));
    }

    static getItem(id) {
      let items = JSON.parse(window.store.get(itemType));

      if (items) {
        return items.find((item) => item.id === id);
      } else {
        return null;
      }
    }

    static getItems() {
      let items = JSON.parse(window.store.get(itemType));

      return items;
    }

    static saveItem(cart, type) {
      window.store.set(type, JSON.stringify(cart));
    }

    static getCart() {
      return window.store.get("cart")
        ? JSON.parse(window.store.get("cart"))
        : [];
    }
  }

  class Utility {
    static checkRefresh() {
      if (window.performance) {
        const navigationEntry =
          window.performance.getEntriesByType("navigation")[0];

        if (navigationEntry) {
          const navigationType = navigationEntry.type || null;

          if (navigationType === "reload") {
            return true;
          } else if (navigationType === "navigate") {
            return false;
          }
        }
      }

      // Default return when the conditions are not met
      return false;
    }

    static getParameterByName(name) {
      var match = RegExp("[?&]" + name + "=([^&]*)").exec(
        window.location.search
      );
      return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    }

    static formatDate(isoDate) {
      let date = new Date(isoDate);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }
      let formattedDate = [dt, month, year].join("-");
      return formattedDate;
    }
  }

  window.addEventListener("load", () => {
    const ui = new UI();
    const items = new Items();

    if (window.location.href.indexOf("?blog") > -1) {
      let blogId = Utility.getParameterByName("blog");
      ui.getBlogUrlParam(blogId);
    } else {
      // setup app
      // ui.setupAPP();

      // get all products, use local storage if user refreshed page instead of doing an API call

      items
        .getItems()
        .then((items) => {
          ui.displayItems(items);
          Storage.saveItems(items);
        })
        .then(() => {
          ui.getItemsButtons();
          // ui.cartLogic();
        });
    }
  });
});
